import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class ScreenSizeService {
    private static instance: ScreenSizeService;
    private screenSizeSubject = new BehaviorSubject<{ width: number, height: number }>({ width: window.innerWidth, height: window.innerHeight });

    private constructor() {
        this.initializeResizeListener();
    }

    static getInstance(): ScreenSizeService {
        if (!ScreenSizeService.instance) {
            ScreenSizeService.instance = new ScreenSizeService();
        }
        return ScreenSizeService.instance;
    }

    private initializeResizeListener() {
        window.addEventListener('resize', () => {
            this.screenSizeSubject.next({ width: window.innerWidth, height: window.innerHeight });
        });
    }

    public get screenSize$() {
        return this.screenSizeSubject.asObservable().pipe(debounceTime(300));
    }
}
