import React, { useEffect, useState } from 'react';
import { useObservable } from '../../utils/useObservable';
import { CustomerService } from '../../services/CustomerService';
import GenericTable from '../../components/Data/GenericTable';
import FilterComponent from '../../components/Data/FilterComponent';
import { FiltersModel } from '../../models/Filters';
import styles from '../../styles/CustomersPage.module.scss';
import { getDefaultPagination } from '../../const/PaginationConst';
import PaginationComponent from '../../components/Data/PaginationComponent';
import StandardButton from '../../components/Buttons/StandardButton';
import { Filter, Add } from '@mui/icons-material';
import { Customer, CreateCustomerDto, UpdateCustomerDto } from '../../models/Customer';
import { ApiResponse } from '../../models/ApiResponse';
import CreateCustomerModal from './CreateCustomerModal';
import EditCustomerModal from './EditCustomerModal';
import DeleteCustomerModal from './DeleteCustomerModal';
import ViewCustomerModal from './ViewCustomerModal';
import { useTranslation } from 'react-i18next';

const customerService = CustomerService.getInstance();

const CustomersPage: React.FC = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<FiltersModel>({});
    const pagination = useObservable(customerService.pagination$, getDefaultPagination());
    const customersResponse = useObservable(customerService.customers$, undefined) as ApiResponse<Customer[]>;
    const optionsMetadata = useObservable(customerService.optionsMetadata$, undefined);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

    useEffect(() => {
        loadCustomers(pagination.currentPage, pagination.pageSize, filters);
        customerService.loadOptionsMetadata();
    }, []);

    const loadCustomers = (pageNumber: number, pageSize: number, filters: FiltersModel) => {
        customerService.loadCustomersWithPagination({ pageNumber, pageSize, filters });
    };

    const handleFilter = (newFilters: FiltersModel) => {
        setFilters(newFilters);
        loadCustomers(pagination.currentPage, pagination.pageSize, newFilters);
    };

    const handlePageChange = (pageNumber: number) => {
        customerService.setPageNumber(pageNumber);
        loadCustomers(pageNumber, pagination.pageSize, filters);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        customerService.setPageSize(newPageSize);
        loadCustomers(pagination.currentPage, newPageSize, filters);
    };

    const handleView = (customer: Customer) => {
        customerService.getCustomerById(customer.id)
            .then(response => {
                if (response.isSuccess) {
                    setSelectedCustomer(customer);
                    setViewModalOpen(true);
                } else {
                    console.error(t('errorRetrievingCustomer'), response.message);
                }
            })
            .catch(error => {
                console.error(t('errorRetrievingCustomer'), error);
            });
    };

    const handleEdit = (customer: Customer) => {
        setSelectedCustomer(customer);
        setEditModalOpen(true);
    };

    const handleDelete = (customer: Customer) => {
        setSelectedCustomer(customer);
        setDeleteModalOpen(true);
    };

    const handleCreate = () => {
        setCreateModalOpen(true);
    };

    const columns = [
        { field: 'customerCode', title: t('customerCode') },
        { field: 'customerName', title: t('customerName') },
    ];

    return (
        <div className={styles.mainContainer}>
            <div className={styles.buttonContainer}>
                <StandardButton
                    variant="primary"
                    onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
                    icon={<Filter />}
                >
                    {t('filter')}
                </StandardButton>
                <StandardButton
                    variant="primary"
                    onClick={handleCreate}
                    icon={<Add />}
                >
                    {t('newCustomer')}
                </StandardButton>
            </div>

            <div className={`${styles.filterPanel} ${isFilterPanelOpen ? styles.open : styles.close}`}>
                {optionsMetadata && (
                    <FilterComponent<Customer>
                        columns={columns}
                        onFilter={handleFilter}
                        optionsMetadata={optionsMetadata}
                        currentFilters={filters}
                    />
                )}
            </div>

            <div className={`${styles.content} ${isFilterPanelOpen ? styles.filtered : ''}`}>
                <div className={styles.tableContainer}>
                    <GenericTable
                        data={customersResponse?.data || []}
                        columns={columns}
                        onView={handleView}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </div>

                <div className={styles.paginationContainer}>
                    <PaginationComponent
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>

            <CreateCustomerModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onCreateCustomer={(newCustomer: CreateCustomerDto) => {
                    customerService.createCustomer(newCustomer)
                        .then(response => {
                            if (response.isSuccess) {
                                loadCustomers(pagination.currentPage, pagination.pageSize, filters);
                                customerService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorCreatingCustomer'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorCreatingCustomer'), error);
                        });
                    setCreateModalOpen(false);
                }}
            />

            <ViewCustomerModal
                open={viewModalOpen}
                onClose={() => setViewModalOpen(false)}
                customer={selectedCustomer}
            />

            <EditCustomerModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                customer={selectedCustomer}
                onUpdateCustomer={(updatedCustomer: UpdateCustomerDto) => {
                    customerService.updateCustomer(updatedCustomer)
                        .then(response => {
                            if (response.isSuccess) {
                                loadCustomers(pagination.currentPage, pagination.pageSize, filters);
                                customerService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorUpdatingCustomer'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorUpdatingCustomer'), error);
                        });
                    setEditModalOpen(false);
                }}
            />

            <DeleteCustomerModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                customer={selectedCustomer}
                onDeleteCustomer={(customerId: string) => {
                    customerService.deleteCustomer(customerId)
                        .then(response => {
                            if (response.isSuccess) {
                                loadCustomers(pagination.currentPage, pagination.pageSize, filters);
                                customerService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorDeletingCustomer'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorDeletingCustomer'), error);
                        });
                    setDeleteModalOpen(false);
                }}
            />
        </div>
    );
};

export default CustomersPage;