import React from 'react';
import styles from '../styles/Spinner.module.scss';
import { ColorRing } from 'react-loader-spinner';

const Spinner: React.FC = () => {
    return (
        <div className={styles.spinner}>
            <ColorRing
                visible={true}
                height="150"
                width="150"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />

        </div>
    );
};

export default Spinner;
