// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StandartButton_standardButton__Wg8d2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-size: 1rem; /* This now equals 10px */
  font-weight: 600;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.StandartButton_standardButton__Wg8d2:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.StandartButton_standardButton__Wg8d2.StandartButton_withIcon__UkdQl .StandartButton_icon__MBkB1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}
.StandartButton_standardButton__Wg8d2.StandartButton_withIcon__UkdQl.StandartButton_iconLeft__Wfz4L .StandartButton_icon__MBkB1 {
  margin-right: 0.8rem;
}
.StandartButton_standardButton__Wg8d2.StandartButton_withIcon__UkdQl.StandartButton_iconRight__4G5uA .StandartButton_icon__MBkB1 {
  margin-left: 0.8rem;
}
.StandartButton_standardButton__Wg8d2.StandartButton_primary__WPQTA {
  background-color: #007bff;
  color: white;
}
.StandartButton_standardButton__Wg8d2.StandartButton_primary__WPQTA:hover:not(:disabled) {
  background-color: #0056b3;
}
.StandartButton_standardButton__Wg8d2.StandartButton_secondary__zFF-j {
  background-color: #6c757d;
  color: white;
}
.StandartButton_standardButton__Wg8d2.StandartButton_secondary__zFF-j:hover:not(:disabled) {
  background-color: #5a6268;
}
.StandartButton_standardButton__Wg8d2.StandartButton_tertiary__Ycfs9 {
  background-color: transparent;
  color: #007bff;
  border: 2px solid #007bff;
}
.StandartButton_standardButton__Wg8d2.StandartButton_tertiary__Ycfs9:hover:not(:disabled) {
  background-color: rgba(0, 123, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/styles/Button/StandartButton.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA,EAAA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;EACA,eAAA;EACA,yBAAA;AACJ;AACI;EACE,YAAA;EACA,mBAAA;AACN;AAGM;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AADR;AAKQ;EACE,oBAAA;AAHV;AAQQ;EACE,mBAAA;AANV;AAWI;EACE,yBAAA;EACA,YAAA;AATN;AAWM;EACE,yBAAA;AATR;AAaI;EACE,yBAAA;EACA,YAAA;AAXN;AAaM;EACE,yBAAA;AAXR;AAeI;EACE,6BAAA;EACA,cAAA;EACA,yBAAA;AAbN;AAeM;EACE,wCAAA;AAbR","sourcesContent":[".standardButton {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    padding: 1rem 2rem;\n    font-size: 1rem; /* This now equals 10px */\n    font-weight: 600;\n    border: none;\n    border-radius: 0.4rem;\n    cursor: pointer;\n    transition: all 0.3s ease;\n    \n    &:disabled {\n      opacity: 0.5;\n      cursor: not-allowed;\n    }\n  \n    &.withIcon {\n      .icon {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 2rem;\n        height: 2rem;\n      }\n  \n      &.iconLeft {\n        .icon {\n          margin-right: 0.8rem;\n        }\n      }\n  \n      &.iconRight {\n        .icon {\n          margin-left: 0.8rem;\n        }\n      }\n    }\n  \n    &.primary {\n      background-color: #007bff;\n      color: white;\n  \n      &:hover:not(:disabled) {\n        background-color: #0056b3;\n      }\n    }\n  \n    &.secondary {\n      background-color: #6c757d;\n      color: white;\n  \n      &:hover:not(:disabled) {\n        background-color: #5a6268;\n      }\n    }\n  \n    &.tertiary {\n      background-color: transparent;\n      color: #007bff;\n      border: 2px solid #007bff;\n  \n      &:hover:not(:disabled) {\n        background-color: rgba(0, 123, 255, 0.1);\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standardButton": `StandartButton_standardButton__Wg8d2`,
	"withIcon": `StandartButton_withIcon__UkdQl`,
	"icon": `StandartButton_icon__MBkB1`,
	"iconLeft": `StandartButton_iconLeft__Wfz4L`,
	"iconRight": `StandartButton_iconRight__4G5uA`,
	"primary": `StandartButton_primary__WPQTA`,
	"secondary": `StandartButton_secondary__zFF-j`,
	"tertiary": `StandartButton_tertiary__Ycfs9`
};
export default ___CSS_LOADER_EXPORT___;
