import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import { WarehouseModel } from '../../models/WarehouseModel';
import { useTranslation } from 'react-i18next';
import { WarehouseService } from '../../services/WarehouseService';

interface WarehouseSelectionModalProps {
    open: boolean;
    onClose: () => void;
    onSelectWarehouse: (warehouse: WarehouseModel) => void;
}

const WarehouseSelectionModal: React.FC<WarehouseSelectionModalProps> = ({ open, onClose, onSelectWarehouse }) => {
    const { t } = useTranslation();
    const [warehouses, setWarehouses] = useState<WarehouseModel[]>([]);
    const [filteredWarehouses, setFilteredWarehouses] = useState<WarehouseModel[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const warehouseService = WarehouseService.getInstance();

    useEffect(() => {
        if (open) {
            loadWarehouses();
        }
    }, [open]);

    const loadWarehouses = () => {
        warehouseService.loadWarehousesWithPagination({ pageNumber: 1, pageSize: 1000, filters: {} })
            .then(() => {
                const warehouseSubscription = warehouseService.warehouses$.subscribe(response => {
                    if (response.isSuccess) {
                        setWarehouses(response.data);
                        setFilteredWarehouses(response.data);
                    }
                });
                return () => warehouseSubscription.unsubscribe();
            });
    };

    useEffect(() => {
        const filtered = warehouses.filter(warehouse =>
            warehouse.whName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            warehouse.whCode.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredWarehouses(filtered);
    }, [searchTerm, warehouses]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('selectWarehouse')}</h2>
                <TextField
                    fullWidth
                    label={t('search')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    margin="normal"
                />
                <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                    {filteredWarehouses.map((warehouse) => (
                        <ListItem
                            key={warehouse.id}
                            button
                            onClick={() => {
                                onSelectWarehouse(warehouse);
                                onClose();
                            }}
                        >
                            <ListItemText
                                primary={warehouse.whName}
                                secondary={warehouse.whCode}
                            />
                        </ListItem>
                    ))}
                </List>
                <Button onClick={onClose} variant="contained" color="secondary" sx={{ mt: 2 }}>
                    {t('cancel')}
                </Button>
            </Box>
        </Modal>
    );
};

export default WarehouseSelectionModal;