import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import { Stock, UpdateStockDto } from '../../models/Stock';
import { useTranslation } from 'react-i18next';

interface EditStockModalProps {
    open: boolean;
    onClose: () => void;
    stock: Stock | null;
    onUpdateStock: (stock: UpdateStockDto) => void;
}

const EditStockModal: React.FC<EditStockModalProps> = ({ open, onClose, stock, onUpdateStock }) => {
    const [stoCode, setStoCode] = useState('');
    const [stoName, setStoName] = useState('');
    const [stoDescription, setStoDescription] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (stock) {
            setStoCode(stock.stoCode);
            setStoName(stock.stoName);
            setStoDescription(stock.stoDescription);
        }
    }, [stock]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (stock) {
            const updatedStock: UpdateStockDto = {
                id: stock.id,
                stoCode,
                stoName,
                stoDescription
            };
            onUpdateStock(updatedStock);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('editStock')}</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label={t('stockCode')}
                        value={stoCode}
                        onChange={(e) => setStoCode(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('stockName')}
                        value={stoName}
                        onChange={(e) => setStoName(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('stockDescription')}
                        value={stoDescription}
                        onChange={(e) => setStoDescription(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t('update')}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default EditStockModal;