import { useState, useCallback, useEffect } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { FiltersModel } from '../../models/Filters';

interface FilterComponentProps<T> {
    columns: { field: string; title: string }[];
    onFilter: (filters: FiltersModel) => void;
    className?: string;
    optionsMetadata: {
        properties: Array<{ name: string; type: string }>;
        distinctValues: { [key: string]: string[] };
    } | null;
    currentFilters: FiltersModel;
    fieldMapping?: { [key: string]: string };
    formatFunctions?: { [key: string]: (value: string) => string };
}

const FilterComponent = <T,>({
    columns,
    onFilter,
    className,
    optionsMetadata,
    currentFilters,
    fieldMapping,
    formatFunctions
}: FilterComponentProps<T>) => {
    const [localFilters, setLocalFilters] = useState<FiltersModel>(currentFilters);

    useEffect(() => {
        setLocalFilters(currentFilters);
    }, [currentFilters]);

    const getOptions = useCallback((field: string): Option[] => {
        const matchingField = Object.keys(optionsMetadata?.distinctValues ?? {}).find(
            key => key.toLowerCase() === field.toLowerCase()
        );

        const distinctValues = optionsMetadata?.distinctValues?.[matchingField ?? ''];

        return Array.isArray(distinctValues)
            ? distinctValues.map(value => ({ label: String(value), value }))
            : [];
    }, [optionsMetadata]);

    const handleFilterChange = useCallback((field: string, selectedOptions: Option[]) => {
        const newFilters = { ...localFilters };
        newFilters[field] = selectedOptions.map(option => option.value);

        if (fieldMapping && fieldMapping[field]) {
            const correspondingField = fieldMapping[field];
            const fieldValues = optionsMetadata?.distinctValues?.[field];
            const correspondingFieldValues = optionsMetadata?.distinctValues?.[correspondingField];

            if (Array.isArray(fieldValues) && Array.isArray(correspondingFieldValues)) {
                const correspondingSelectedValues = selectedOptions
                    .map(option => {
                        const index = fieldValues.indexOf(option.value as string);
                        return index !== -1 ? correspondingFieldValues[index] : null;
                    })
                    .filter((value): value is string => value !== null);

                newFilters[correspondingField] = correspondingSelectedValues;
            } else if (formatFunctions && formatFunctions[field]) {
                const formatFunction = formatFunctions[field];
                newFilters[correspondingField] = selectedOptions.map(option => formatFunction(option.value as string));
            }
        }

        setLocalFilters(newFilters);
        onFilter(newFilters);
    }, [localFilters, onFilter, optionsMetadata, fieldMapping, formatFunctions]);

    if (!optionsMetadata) {
        return null;
    }

    return (
        <div className={className}>
            {columns.map(column => {
                const options = getOptions(column.field);
                const selectedValues = localFilters[column.field] || [];

                return (
                    <div key={column.field}>
                        <label>{column.title}</label>
                        <MultiSelect
                            options={options}
                            value={options.filter(option => selectedValues.includes(option.value))}
                            onChange={(selectedOptions: Option[]) => handleFilterChange(column.field, selectedOptions)}
                            labelledBy={`${column.field}-label`}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default FilterComponent;
