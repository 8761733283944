import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { OrderModel, OrderType } from '../../models/Order';
import { useTranslation } from 'react-i18next';

interface ViewOrderModalProps {
    open: boolean;
    onClose: () => void;
    order: OrderModel | null;
}

const ViewOrderModal: React.FC<ViewOrderModalProps> = ({ open, onClose, order }) => {
    const { t } = useTranslation();

    if (!order) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    {t('orderDetails')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <strong>{t('orderDate')}:</strong> {order.ordDate}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('documentSerial')}:</strong> {order.ordDocSerial}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('sequence')}:</strong> {order.ordSequence}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('orderType')}:</strong> {OrderType[order.orderType]}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('customer')}:</strong> {order.ordCustomerName}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('warehouse')}:</strong> {order.ordWarehouseName}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('createdDate')}:</strong> {new Date(order.cruDate).toLocaleString()}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} variant="contained">
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewOrderModal;