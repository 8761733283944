import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { OrderModel, UpdateOrderDto, OrderType } from '../../models/Order';
import { useTranslation } from 'react-i18next';
import { WarehouseModel } from '../../models/WarehouseModel';
import { Customer } from '../../models/Customer';
import CustomerSelectionModal from '../Customer/CustomerSelectionModal';
import WarehouseSelectionModal from '../Warehouse/WarehouseSelectionModal';

interface EditOrderModalProps {
    open: boolean;
    onClose: () => void;
    order: OrderModel | null;
    onUpdateOrder: (order: UpdateOrderDto) => void;
}

const EditOrderModal: React.FC<EditOrderModalProps> = ({ open, onClose, order, onUpdateOrder }) => {
    const [ordDate, setOrdDate] = useState<string>('');
    const [ordDocSerial, setOrdDocSerial] = useState('');
    const [ordSequence, setOrdSequence] = useState(0);
    const [orderType, setOrderType] = useState<OrderType>(OrderType.Type1);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseModel | null>(null);
    const [warehouseSelectionModalOpen, setWarehouseSelectionModalOpen] = useState(false);
    const [customerSelectionModalOpen, setCustomerSelectionModalOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (order) {
            setOrdDate(order.ordDate);
            setOrdDocSerial(order.ordDocSerial);
            setOrdSequence(order.ordSequence);
            setOrderType(order.orderType);
            setSelectedCustomer({ id: order.ordCustomerId, customerName: order.ordCustomerName } as Customer);
            setSelectedWarehouse({ id: order.ordWarehouseId, whName: order.ordWarehouseName } as WarehouseModel);
        }
    }, [order]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!order || !selectedCustomer || !selectedWarehouse) {
            alert(t('pleaseSelectCustomerAndWarehouse'));
            return;
        }
        const updatedOrder: UpdateOrderDto = {
            id: order.id,
            ordDate,
            ordDocSerial,
            ordSequence,
            orderType,
            ordCustomerId: selectedCustomer.id,
            ordWarehouseId: selectedWarehouse.id
        };
        onUpdateOrder(updatedOrder);
        onClose();
    };

    if (!order) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('editOrder')}</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label={t('orderDate')}
                        type="date"
                        value={ordDate}
                        onChange={(e) => setOrdDate(e.target.value)}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        fullWidth
                        label={t('documentSerial')}
                        value={ordDocSerial}
                        onChange={(e) => setOrdDocSerial(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('sequence')}
                        type="number"
                        value={ordSequence}
                        onChange={(e) => setOrdSequence(Number(e.target.value))}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('orderType')}</InputLabel>
                        <Select
                            value={orderType}
                            onChange={(e) => setOrderType(e.target.value as OrderType)}
                        >
                            <MenuItem value={OrderType.Type1}>{t('orderType1')}</MenuItem>
                            <MenuItem value={OrderType.Type2}>{t('orderType2')}</MenuItem>
                            <MenuItem value={OrderType.Type3}>{t('orderType3')}</MenuItem>
                        </Select>
                    </FormControl>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label={t('customer')}
                                value={selectedCustomer ? selectedCustomer.customerName : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                onClick={() => setCustomerSelectionModalOpen(true)}
                                fullWidth
                            >
                                {t('selectCustomer')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label={t('warehouse')}
                                value={selectedWarehouse ? selectedWarehouse.whName : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                onClick={() => setWarehouseSelectionModalOpen(true)}
                                fullWidth
                            >
                                {t('selectWarehouse')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t('update')}
                    </Button>
                </form>
                <CustomerSelectionModal
                    open={customerSelectionModalOpen}
                    onClose={() => setCustomerSelectionModalOpen(false)}
                    onSelectCustomer={setSelectedCustomer}
                />
                <WarehouseSelectionModal
                    open={warehouseSelectionModalOpen}
                    onClose={() => setWarehouseSelectionModalOpen(false)}
                    onSelectWarehouse={setSelectedWarehouse}
                />
            </Box>
        </Modal>
    );
};

export default EditOrderModal;