// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportPage_support-page__ztmab {
  font-size: 1.6rem;
}

.SupportPage_navbar__0l4Ly {
  background-color: #3F52B5;
  color: white;
  padding: 1rem;
}

.SupportPage_navbar__0l4Ly h1 {
  font-size: 2.4rem;
}

.SupportPage_content__-e2Pz {
  max-width: 60rem;
  margin: 2rem auto;
  padding: 2 1rem;
  gap: 2;
}

.SupportPage_content__-e2Pz h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
}

.SupportPage_sendButton__fWmq2 {
  background-color: #3F52B5;
  color: white;
  border: none;
  top: auto;
  padding: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.SupportPage_container__FJcGU {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 98vh;
  background-color: #f8fcff;
  border-radius: 20px;
}

.SupportPage_message__dUZ5h {
  width: 100%;
  height: 20rem;
  padding-top: 10px;
  padding: 1rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  resize: vertical;
}`, "",{"version":3,"sources":["webpack://./src/styles/SupportPage.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AACA;EACE,yBAAA;EACA,YAAA;EACA,aAAA;AAEF;;AAAA;EACE,iBAAA;AAGF;;AADA;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,MAAA;AAIF;;AAFA;EACE,eAAA;EACA,mBAAA;AAKF;;AAHA;EACE,aAAA;EACA,sBAAA;AAMF;;AAJA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,SAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;AAOF;;AALA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;AAQF;;AANA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;AASF","sourcesContent":[".support-page {\n  font-size: 1.6rem;\n}\n.navbar {\n  background-color: #3F52B5;\n  color: white;\n  padding: 1rem;\n}\n.navbar h1 {\n  font-size: 2.4rem;\n}\n.content {\n  max-width: 60rem;\n  margin: 2rem auto;\n  padding: 2 1rem;\n  gap:2;\n}\n.content h2 {\n  font-size: 2rem;\n  margin-bottom: 1rem;\n}\nform {\n  display: flex;\n  flex-direction: column;\n}\n.sendButton {\n  background-color: #3F52B5;\n  color: white;\n  border: none;\n  top: auto;\n  padding: 1rem;\n  font-size: 1.6rem;\n  cursor: pointer;\n  margin-bottom: 1rem;\n}\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 98vh;\n  background-color: #f8fcff;\n  border-radius: 20px;\n}\n.message {\n  width: 100%;\n  height: 20rem;\n  padding-top: 10px;\n  padding: 1rem;\n  font-size: 1.6rem;\n  border: 1px solid #ccc;\n  resize: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support-page": `SupportPage_support-page__ztmab`,
	"navbar": `SupportPage_navbar__0l4Ly`,
	"content": `SupportPage_content__-e2Pz`,
	"sendButton": `SupportPage_sendButton__fWmq2`,
	"container": `SupportPage_container__FJcGU`,
	"message": `SupportPage_message__dUZ5h`
};
export default ___CSS_LOADER_EXPORT___;
