import React from 'react';
import { Shield, Eye, Lock, Trash2, MessageCircle, HelpCircle } from 'lucide-react';

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-[#3F52B5] p-6 text-white">
        <h1 className="text-3xl font-bold">Children Wear Platform</h1>
      </nav>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-4xl font-semibold mb-8">Privacy Policy</h2>
        
        <div className="space-y-8">
          <section className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <Shield className="mr-3 h-6 w-6" /> Information We Collect
            </h3>
            <p className="text-gray-700 text-lg">
              We collect information you provide directly to us, such as when you create an account, make a purchase, or contact customer support. This may include your name, email address, shipping address, payment information, and phone number.
            </p>
          </section>
          
          <section className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <Eye className="mr-5 h-6 w-6" /> How We Use Your Information
            </h3>
            <ul className="list-disc list-inside text-gray-700 text-lg space-y-2">
              <li>To provide and maintain our services</li>
              <li>To process transactions and send transaction notifications</li>
              <li>To personalize your shopping experience</li>
              <li>To communicate with you about products, services, and promotions</li>
              <li>To improve our website and customer service</li>
            </ul>
          </section>
          
          <section className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <Lock className="mr-3 h-6 w-6" /> Data Security
            </h3>
            <p className="text-gray-700 text-lg">
              We implement appropriate technical and organizational measures to protect the security of your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.
            </p>
          </section>
          
          <section className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <Trash2 className="mr-3 h-6 w-6" /> Data Retention and Deletion
            </h3>
            <p className="text-gray-700 text-lg">
              We retain your personal information for as long as necessary to provide you with our services and as described in this privacy policy. You may request deletion of your personal data at any time by contacting us.
            </p>
          </section>
          
          <section className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <MessageCircle className="mr-3 h-6 w-6" /> Contact Us
            </h3>
            <p className="text-gray-700 text-lg">
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <p className="text-gray-700 text-lg mt-2">
              Email: privacy@childrenwearplatform.com<br />
              Address: 123 Fashion Street, Style City, 12345
            </p>
          </section>
          
          <section className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <HelpCircle className="mr-3 h-6 w-6" /> Changes to This Policy
            </h3>
            <p className="text-gray-700 text-lg">
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.
            </p>
          </section>
        </div>
        
        <p className="text-gray-600 text-center mt-8">
          Last updated: September 19, 2024
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;