import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { OrderModel } from '../../models/Order';
import { useTranslation } from 'react-i18next';

interface DeleteOrderModalProps {
    open: boolean;
    onClose: () => void;
    order: OrderModel | null;
    onDeleteOrder: (orderId: string) => void;
}

const DeleteOrderModal: React.FC<DeleteOrderModalProps> = ({ open, onClose, order, onDeleteOrder }) => {
    const { t } = useTranslation();

    const handleDelete = () => {
        if (order) {
            onDeleteOrder(order.id);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    {t('deleteOrder')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {order
                        ? t('deleteOrderConfirmation', { code: order.ordDocSerial, date: order.ordSequence })
                        : "xxxxxxxxx"
                    }
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDelete} variant="contained" color="error">
                        {t('delete')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteOrderModal;