import { BehaviorSubject } from "rxjs";
import { AppStatusModel } from "../models/AppStatus";



export class AppService { 
  private static instance: AppService;

  static getInstance(): AppService {
    if (!AppService.instance) {
      AppService.instance = new AppService();  
    }
    return AppService.instance;
  }

  private serviceStatusSubject = new BehaviorSubject<AppStatusModel>({ error: '', isLoading: false });
   
  public setError(message:string)  
  {
      this.serviceStatusSubject.next({...this.serviceStatusSubject.value, error:message})
  }

  public setLoading(isLoading:boolean)  
  {
      this.serviceStatusSubject.next({...this.serviceStatusSubject.value, isLoading:isLoading})
  }
 
  get serviceStatus$() {
    return this.serviceStatusSubject.asObservable();
  }
 
}