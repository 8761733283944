// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_loginContainer__AOAMx {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 98vh;
  background-color: #f8fcff;
  border-radius: 20px;
}
.LoginPage_loginContainer__AOAMx .LoginPage_logo__QgH1p {
  border-radius: 20px;
  width: 40%;
  margin-bottom: 0;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz {
  width: 20%;
  padding: 2rem;
  background: #07183a;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz h2 {
  font-size: 1.4rem;
  color: #fdfdfd;
  margin-bottom: 1.5rem;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz .LoginPage_formGroup__iqWwD {
  margin-bottom: 20px;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz .LoginPage_formGroup__iqWwD label {
  font-size: 1.2rem;
  color: #005488;
  margin-bottom: 0.5rem;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz .LoginPage_formGroup__iqWwD input {
  width: 93%;
  padding: 0.6rem;
  font-size: 1.1rem;
  border: 2px solid #b3d9ff;
  border-radius: 10px;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz .LoginPage_formGroup__iqWwD input:focus {
  border-color: #0056b3;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz .LoginPage_submitButton__R54b5 {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.LoginPage_loginContainer__AOAMx .LoginPage_loginForm__DRqKz .LoginPage_submitButton__R54b5:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}`, "",{"version":3,"sources":["webpack://./src/styles/LoginPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EAEC,mBAAA;EACC,UAAA;EACA,gBAAA;AAAJ;AAGE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yCAAA;AADJ;AAGI;EACE,iBAAA;EACA,cAAA;EACA,qBAAA;AADN;AAII;EAEE,mBAAA;AAHN;AAKM;EACE,iBAAA;EACA,cAAA;EACA,qBAAA;AAHR;AAMM;EACG,UAAA;EACD,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AAJR;AAKQ;EACE,qBAAA;AAHV;AAQI;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,iDAAA;AANN;AAQM;EACE,yBAAA;EACA,2BAAA;AANR","sourcesContent":[".loginContainer {\n  display: flex; \n  align-items: center; \n  justify-content: center;\n  min-height: 98vh;\n  background-color: #f8fcff;\n  border-radius: 20px;\n\n  .logo {  \n   \n   border-radius: 20px;\n    width: 40%; \n    margin-bottom: 0; \n  }\n\n  .loginForm {\n    width: 20%; \n    padding: 2rem;\n    background: #07183a;\n    border-radius: 20px;\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);\n    \n    h2 {\n      font-size: 1.4rem;\n      color: #fdfdfd;\n      margin-bottom: 1.5rem;\n    }\n\n    .formGroup {\n      \n      margin-bottom: 20px; \n\n      label {\n        font-size: 1.2rem;\n        color: #005488;\n        margin-bottom: 0.5rem;\n      }\n\n      input {\n         width:93%;\n        padding: 0.6rem;\n        font-size: 1.1rem;\n        border: 2px solid #b3d9ff;\n        border-radius: 10px;\n        &:focus {\n          border-color: #0056b3;\n        }\n      }\n    }\n\n    .submitButton {\n      width: 100%;\n      padding: 1rem;\n      font-size: 1.2rem;\n      color: #ffffff;\n      background-color: #007bff;\n      border-radius: 10px;\n      cursor: pointer;\n      transition: background-color 0.3s, transform 0.2s;\n\n      &:hover {\n        background-color: #0056b3;\n        transform: translateY(-2px);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": `LoginPage_loginContainer__AOAMx`,
	"logo": `LoginPage_logo__QgH1p`,
	"loginForm": `LoginPage_loginForm__DRqKz`,
	"formGroup": `LoginPage_formGroup__iqWwD`,
	"submitButton": `LoginPage_submitButton__R54b5`
};
export default ___CSS_LOADER_EXPORT___;
