import React from 'react';
import { AlertCircle, Star, Package } from 'lucide-react';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-[#3F52B5] p-6 text-white">
        <h1 className="text-3xl font-bold">Children Wear Platform</h1>
      </nav>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-4xl font-semibold mb-8">About Our Platform</h2>
        
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <AlertCircle className="mr-3 h-6 w-6" /> Our Mission
            </h3>
            <p className="text-gray-700 text-lg">
              Children Wear Platform is dedicated to providing high-quality, comfortable, and stylish clothing options for children. We believe that every child deserves to feel confident and express their personality through fashion.
            </p>
          </div>
          
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <Star className="mr-3 h-6 w-6" /> Key Features
            </h3>
            <ul className="list-disc list-inside text-gray-700 text-lg space-y-2">
              <li>Wide range of sizes for all ages</li>
              <li>Eco-friendly and sustainable clothing options</li>
              <li>User-friendly shopping experience</li>
              <li>Regular new arrivals and seasonal collections</li>
            </ul>
          </div>
          
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold mb-4 flex items-center">
              <Package className="mr-3 h-6 w-6" /> Latest Release
            </h3>
            <div className="text-gray-700">
              <p className="font-semibold text-lg">Version 0.0.1 (3) - September 2024 Update</p>
              <ul className="list-disc list-inside mt-4 space-y-2 text-lg">
                <li>Introduced a new summer collection</li>
                <li>Improved size recommendation algorithm</li>
                <li>Bug fixes and performance improvements</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;