import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { CreateOrderDto, OrderModel, UpdateOrderDto } from "../models/Order";
import { ApiResponse } from "../models/ApiResponse";
import { PaginationConst, PaginationModel, getDefaultPagination } from "../const/PaginationConst";
import { PaginationRequestModel } from "../models/PaginationRequestModel";
import UrlLinks from "../const/UrlLinks";
import { OptionsMetadata } from "../models/OptionsMetadata";
import { AppService } from "./AppService";

export class OrderService {
  private appService = AppService.getInstance();
  private static instance: OrderService;
  private ordersSubject = new BehaviorSubject<ApiResponse<OrderModel[]>>({
    data: [],
    isSuccess: false,
    message: '',
    status: 0,
    pagination: getDefaultPagination(),
  });

  private paginationSubject = new BehaviorSubject<PaginationModel>(getDefaultPagination());
  private optionsMetadataSubject = new BehaviorSubject<OptionsMetadata | null>(null);

  private constructor() {}

  static getInstance(): OrderService {
    if (!OrderService.instance) {
      OrderService.instance = new OrderService();
    }
    return OrderService.instance;
  }

  public async loadOrdersWithPagination(request: PaginationRequestModel): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<OrderModel[]>>(UrlLinks.getAllOrdersUrl, request);
      if (response.data.isSuccess) {
        this.ordersSubject.next(response.data);
        this.updatePagination(response.data.pagination as PaginationModel);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching orders with pagination:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);
    }
  }

  private updatePagination(pagination: PaginationModel) {
    this.paginationSubject.next({
      currentPage: pagination.currentPage,
      pageSize: pagination.pageSize,
      totalPages: pagination.totalPages,
      totalRecords: pagination.totalRecords,
    });
  }

  public setPageSize(pageSize: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, pageSize });
    this.loadOrdersWithPagination({ 
      pageNumber: PaginationConst.DEFAULT_PAGE_NUMBER, 
      pageSize, 
      filters: {} 
    });
  }

  public setPageNumber(pageNumber: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, currentPage: pageNumber });
    this.loadOrdersWithPagination({ 
      pageNumber, 
      pageSize: currentPagination.pageSize, 
      filters: {} 
    });
  }

  public async loadOptionsMetadata(): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.options<ApiResponse<OptionsMetadata>>(UrlLinks.getOrderOptionsUrl);
      if (response.data.isSuccess) {
        this.optionsMetadataSubject.next(response.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching options metadata:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async createOrder(order: CreateOrderDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<void>>(UrlLinks.createOrderUrl, order);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error creating order:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async updateOrder(order: UpdateOrderDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.put<ApiResponse<void>>(UrlLinks.updateOrderUrl, order);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating order:', error);
      const errorMessage = axios.isAxiosError(error) && error.response ? error.response.data.message : String(error);
      this.appService.setError(errorMessage);
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async deleteOrder(id: string): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.delete<ApiResponse<void>>(`${UrlLinks.deleteOrderUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error deleting order:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async getOrderById(id: string): Promise<ApiResponse<OrderModel>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.get<ApiResponse<OrderModel>>(`${UrlLinks.getOrderByIdUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching order by id:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  get orders$() {
    return this.ordersSubject.asObservable();
  }

  get pagination$() {
    return this.paginationSubject.asObservable();
  }

  get optionsMetadata$() {
    return this.optionsMetadataSubject.asObservable();
  }
}