import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'react-country-flag';
import styles from '../styles/LanguageSelector.module.scss';

const LanguageSelector: React.FC = () => {
    const { i18n } = useTranslation();

    const languageOptions = [
        { value: 'en', label: 'English', countryCode: 'GB' },
        { value: 'tr', label: 'Türkçe', countryCode: 'TR' },
        { value: 'zh', label: '中文', countryCode: 'CN' }
    ];

    const formatOptionLabel = ({ label, countryCode }: { label: string; countryCode: string }) => (
        <div className={styles.option}>
            <CountryFlag countryCode={countryCode} svg />
            <span>{label}</span>
        </div>
    );

    const handleChange = (option: any) => {
        i18n.changeLanguage(option.value);
    };

    return (
        <Select
            className={styles.languageSelector}
            options={languageOptions}
            defaultValue={languageOptions.find(option => option.value === i18n.language)}
            onChange={handleChange}
            styles={{
                control: (base) => ({
                    ...base,
                    className: styles.control
                }),
                option: (base, state) => ({
                    ...base,
                    className: styles.option
                }),
                singleValue: (base) => ({
                    ...base,
                    className: styles.singleValue
                })
            }}
            formatOptionLabel={formatOptionLabel}
            isSearchable={false}
        />
    );
};

export default LanguageSelector;
