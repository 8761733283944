import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { Customer } from '../../models/Customer';
import { useTranslation } from 'react-i18next';

interface DeleteCustomerModalProps {
    open: boolean;
    onClose: () => void;
    customer: Customer | null;
    onDeleteCustomer: (customerId: string) => void;
}

const DeleteCustomerModal: React.FC<DeleteCustomerModalProps> = ({ open, onClose, customer, onDeleteCustomer }) => {
    const { t } = useTranslation();

    const handleDelete = () => {
        if (customer) {
            onDeleteCustomer(customer.id);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    {t('deleteCustomer')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {customer
                        ? t('deleteConfirmation', { name: customer.customerName, code: customer.customerCode })
                        : t('deleteConfirmation', { name: '', code: '' })}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDelete} variant="contained" color="error">
                        {t('delete')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteCustomerModal;