import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import { CreateStockDto } from '../../models/Stock';
import { useTranslation } from 'react-i18next';

interface CreateStockModalProps {
    open: boolean;
    onClose: () => void;
    onCreateStock: (stock: CreateStockDto) => void;
}

const CreateStockModal: React.FC<CreateStockModalProps> = ({ open, onClose, onCreateStock }) => {
    const [stoCode, setStoCode] = useState('');
    const [stoName, setStoName] = useState('');
    const [stoDescription, setStoDescription] = useState('');
    const [stoCanBeBelowZero, setStoCanBeBelowZero] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const newStock: CreateStockDto = {
            stoCode,
            stoName,
            stoDescription,
            stoCanBeBelowZero
        };
        onCreateStock(newStock);
        setStoCode('');
        setStoName('');
        setStoDescription('');
        setStoCanBeBelowZero(false);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('newStock')}</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label={t('stockCode')}
                        value={stoCode}
                        onChange={(e) => setStoCode(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('stockName')}
                        value={stoName}
                        onChange={(e) => setStoName(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('stockDescription')}
                        value={stoDescription}
                        onChange={(e) => setStoDescription(e.target.value)}
                        margin="normal"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={stoCanBeBelowZero}
                                onChange={(e) => setStoCanBeBelowZero(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={t('canBeBelowZero')}
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t('create')}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateStockModal;