import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { Stock } from '../../models/Stock';
import { useTranslation } from 'react-i18next';

interface DeleteStockModalProps {
    open: boolean;
    onClose: () => void;
    stock: Stock | null;
    onDeleteStock: (stockId: string) => void;
}

const DeleteStockModal: React.FC<DeleteStockModalProps> = ({ open, onClose, stock, onDeleteStock }) => {
    const { t } = useTranslation();

    const handleDelete = () => {
        if (stock) {
            onDeleteStock(stock.id);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    {t('deleteStock')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {stock
                        ? t('deleteStockConfirmation', { name: stock.stoName, code: stock.stoCode })
                        : t('deleteStockConfirmation', { name: '', code: '' })}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDelete} variant="contained" color="error">
                        {t('delete')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteStockModal;