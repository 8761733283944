export enum OrderType {
  Type1 = 0,
  Type2 = 1,
  Type3 = 2
}

export interface OrderModel {
  id: string;
  ordDate: string;
  ordDocSerial: string;
  ordSequence: number;
  orderType: OrderType;
  ordCustomerId: string;
  ordCustomerName: string;
  ordWarehouseId: string;
  ordWarehouseName: string;
  cruDate: string;
}

export interface CreateOrderDto {
  ordDate: string;
  ordDocSerial: string;
  ordSequence: number;
  orderType: OrderType;
  ordCustomerId: string;
  ordWarehouseId: string;
}

export interface UpdateOrderDto {
  id: string;
  ordDate: string;
  ordDocSerial: string;
  ordSequence: number;
  orderType: OrderType;
  ordCustomerId: string;
  ordWarehouseId: string;
}