import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { CreateWarehouseDto, WarehouseModel, UpdateWarehouseDto, ReadWarehouseDto } from "../models/WarehouseModel";
import { ApiResponse } from "../models/ApiResponse";
import { PaginationConst, PaginationModel, getDefaultPagination } from "../const/PaginationConst";
import { PaginationRequestModel } from "../models/PaginationRequestModel";
import UrlLinks from "../const/UrlLinks";
import { OptionsMetadata } from "../models/OptionsMetadata";
import { AppService } from "./AppService";

export class WarehouseService {
  private appService = AppService.getInstance();
  private static instance: WarehouseService;
  private warehousesSubject = new BehaviorSubject<ApiResponse<WarehouseModel[]>>({
    data: [],
    isSuccess: false,
    message: '',
    status: 0,
    pagination: getDefaultPagination(),
  });

  private paginationSubject = new BehaviorSubject<PaginationModel>(getDefaultPagination());
  private optionsMetadataSubject = new BehaviorSubject<OptionsMetadata | null>(null);

  private constructor() {}

  static getInstance(): WarehouseService {
    if (!WarehouseService.instance) {
      WarehouseService.instance = new WarehouseService();
    }
    return WarehouseService.instance;
  }

  public async loadWarehousesWithPagination(request: PaginationRequestModel): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<WarehouseModel[]>>(UrlLinks.getAllWarehouseUrl, request);
      if (response.data.isSuccess) {
        this.warehousesSubject.next(response.data);
        this.updatePagination(response.data.pagination as PaginationModel);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching warehouses with pagination:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);
    }
  }

  private updatePagination(pagination: PaginationModel) {
    this.paginationSubject.next({
      currentPage: pagination.currentPage,
      pageSize: pagination.pageSize,
      totalPages: pagination.totalPages,
      totalRecords: pagination.totalRecords,
    });
  }

  public setPageSize(pageSize: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, pageSize });
    this.loadWarehousesWithPagination({ 
      pageNumber: PaginationConst.DEFAULT_PAGE_NUMBER, 
      pageSize, 
      filters: {} 
    });
  }

  public setPageNumber(pageNumber: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, currentPage: pageNumber });
    this.loadWarehousesWithPagination({ 
      pageNumber, 
      pageSize: currentPagination.pageSize, 
      filters: {} 
    });
  }

  public async loadOptionsMetadata(): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.options<ApiResponse<OptionsMetadata>>(UrlLinks.getWarehouseOptionsUrl);
      if (response.data.isSuccess) {
        this.optionsMetadataSubject.next(response.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching options metadata:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async createWarehouse(warehouse: CreateWarehouseDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<void>>(UrlLinks.createWarehouseUrl, warehouse);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error creating warehouse:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async updateWarehouse(warehouse: UpdateWarehouseDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.put<ApiResponse<void>>(UrlLinks.updateWarehouseUrl, warehouse);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating warehouse:', error);
      const errorMessage = axios.isAxiosError(error) && error.response ? error.response.data.message : String(error);
      this.appService.setError(errorMessage);
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async deleteWarehouse(id: string): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.delete<ApiResponse<void>>(`${UrlLinks.deleteWarehouseUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error deleting warehouse:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async getWarehouseById(id: string): Promise<ApiResponse<ReadWarehouseDto>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.get<ApiResponse<ReadWarehouseDto>>(`${UrlLinks.getWarehouseByIdUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching warehouse by id:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  get warehouses$() {
    return this.warehousesSubject.asObservable();
  }

  get pagination$() {
    return this.paginationSubject.asObservable();
  }

  get optionsMetadata$() {
    return this.optionsMetadataSubject.asObservable();
  }
}