import React, { useState } from 'react';
import styles from '../../styles/SupportPage.module.scss';
import axios from 'axios';
import UrlLinks from '../../const/UrlLinks';
import { toast } from 'react-toastify';
import { t } from 'i18next';


interface Support {
    comment:string,
    email:string,
    number:string
}

const SupportPage = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: Support = {
      comment:message,
      email,
      number:phoneNumber
    };


    try {
      const result = await axios.post(UrlLinks.supportUrl, data);

      toast.success(t('supportSendSucces'));
      // Handle successful submission (e.g., show a success message)
      setMessage('');
      setEmail('');
      setPhoneNumber('');
      
    } catch (error) {
        toast.error(t('error', { error }));
      console.error('Error submitting support request:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };


  return (
    <div className={styles.supportPage}>
      <nav className={styles.navbar}>
        <h1>Children Wear Platform</h1>
      </nav>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h2>Support</h2>
          <form onSubmit={handleSubmit}>
            <button type="submit" className={styles.sendButton}>
              Send Message
            </button>
            <input
              value={email}
              maxLength={50}
              type='email'
              style={{padding:5,marginTop:2,fontSize:12}}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
              required
            />
            <input
              value={phoneNumber}
              maxLength={50}
              style={{padding:5,marginTop:2,fontSize:12}}
              type='number'
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="phone number"
              required
            />              
            <textarea
              className={styles.message}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              required
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;