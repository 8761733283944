import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { authService } from '../services/AuthService';
import Spinner from '../components/Spinner';


const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const checkAuth = () => {
            const token = localStorage.getItem('token');
            if (token) {
                authService.validateToken(token).subscribe({
                    next: response => {
                        setIsAuthenticated(response.isSuccess);
                        setIsLoading(false);
                    },
                    error: () => {
                        localStorage.removeItem('token');
                        setIsAuthenticated(false);
                        setIsLoading(false);
                    }
                });
            } else {
                setIsAuthenticated(false);
                setIsLoading(false);
            }
        };

        checkAuth();
    }, []);


    if (isLoading) {
        return <div><Spinner /></div>;
    }

    return isAuthenticated ? (
        <Component {...rest} />
    ) : (
        <Navigate to="/login" replace state={{ from: location }} />
    );
};

export default PrivateRoute;
