// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageSelector_languageSelector__control__Y6YQL {
  min-height: 36px;
  font-size: 16px;
  border-color: #ddd;
  box-shadow: none;
}
.LanguageSelector_languageSelector__control__Y6YQL:hover {
  border-color: #aaa;
}
.LanguageSelector_languageSelector__option__zK814 {
  color: #333;
  font-size: 16px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.LanguageSelector_languageSelector__option__zK814:hover {
  background-color: #f0f0f0;
}
.LanguageSelector_languageSelector__option__zK814 > span {
  margin-left: 10px;
}
.LanguageSelector_languageSelector__single-value__AVm\\+w {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/LanguageSelector.module.scss"],"names":[],"mappings":"AAEE;EAEE,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAME;EAEE,WAAA;EACA,eAAA;EACA,sBAAA;EAMA,aAAA;EACA,mBAAA;AAVJ;AAKI;EACE,yBAAA;AAHN;AASI;EACE,iBAAA;AAPN;AAWE;EACE,aAAA;EACA,mBAAA;AATJ","sourcesContent":[".languageSelector {\n \n  &__control { \n   \n    min-height: 36px;\n    font-size: 16px;\n    border-color: #ddd;\n    box-shadow: none;\n\n    &:hover {\n      border-color: #aaa;\n    }\n  }\n\n  &__option { \n     \n    color: #333;\n    font-size: 16px;\n    background-color: #fff;\n\n    &:hover {\n      background-color: #f0f0f0;\n    }\n\n    display: flex;\n    align-items: center; \n\n    & > span {  \n      margin-left: 10px; \n    }\n  }\n\n  &__single-value {  \n    display: flex;\n    align-items: center;  \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelector__control": `LanguageSelector_languageSelector__control__Y6YQL`,
	"languageSelector__option": `LanguageSelector_languageSelector__option__zK814`,
	"languageSelector__single-value": `LanguageSelector_languageSelector__single-value__AVm+w`
};
export default ___CSS_LOADER_EXPORT___;
