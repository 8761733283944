import React, { useEffect, useState } from 'react';
import { authService } from '../../services/AuthService';
import { useNavigate, useLocation } from 'react-router-dom';
import { menuItems } from './MenuItems';
import Header from './Header';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import { ScreenSizeService } from '../../services/ScreenSizeService';
import { useObservable } from '../../utils/useObservable';

const screenSizeService = ScreenSizeService.getInstance();

const DashboardPage: React.FC = () => {
    const screenSize = useObservable(screenSizeService.screenSize$, { width: window.innerWidth, height: window.innerHeight });
    const [drawerOpen, setDrawerOpen] = useState(true);

    const drawerWidthPercentage = 15 / 100;
    const drawerMaxWidthPercentage = 20 / 100;
    const [drawerWidth, setDrawerWidth] = useState(() => window.innerWidth * drawerWidthPercentage);
    const appBarHeight = screenSize.height * 0.09;

    useEffect(() => {
        setDrawerWidth(screenSize.width * drawerWidthPercentage);
    }, [screenSize.width]);

    const navigate = useNavigate();
    const location = useLocation();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleResize = (event: React.SyntheticEvent, { size }: { size: { width: number, height: number } }) => {
        const newWidth = Math.min(size.width, screenSize.width * drawerMaxWidthPercentage);
        setDrawerWidth(newWidth);
    };

    const logoutHandler = async () => {
        try {
            authService.clearToken();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
        <div>
            <Header
                handleDrawerToggle={handleDrawerToggle}
                logoutHandler={logoutHandler}
                appBarHeight={appBarHeight}
            />
            <Sidebar
                drawerOpen={drawerOpen}
                drawerWidth={drawerWidth}
                handleResize={handleResize}
                menuItems={menuItems}
                location={location}
            />
            <MainContent
                drawerOpen={drawerOpen}
                drawerWidth={drawerWidth}
                menuItems={menuItems}
                appBarHeight={appBarHeight}
            />
        </div>
    );
};

export default DashboardPage;