import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MenuItemModel } from './MenuItems';
import styles from '../../styles/Dashboard.module.scss';

interface MainContentProps {
    drawerOpen: boolean;
    drawerWidth: number;
    appBarHeight: number;
    menuItems: MenuItemModel[];
}

const MainContent: React.FC<MainContentProps> = ({ drawerOpen, drawerWidth, menuItems, appBarHeight }) => {
    function recursiveRouteBuilder(menuItems: MenuItemModel[]): React.ReactNode[] {
        return menuItems.flatMap((menuItem) => {
            if (menuItem.subMenuItems) {
                return recursiveRouteBuilder(menuItem.subMenuItems);
            } else if (menuItem.url && menuItem.element) {
                return <Route key={menuItem.url} path={menuItem.url} element={menuItem.element} />;
            }
            return null;
        });
    }

    return (
        <main style={{ marginLeft: drawerOpen ? drawerWidth : 0, marginTop: appBarHeight }}>
            <div className={styles.mainContent}>
                <Routes>
                    {menuItems.flatMap((mainItem) => {
                        if (mainItem.subMenuItems) {
                            return recursiveRouteBuilder(mainItem.subMenuItems);
                        } else if (mainItem.url && mainItem.element) {
                            return <Route key={mainItem.url} path={mainItem.url} element={mainItem.element} />;
                        }
                        return null;
                    })}
                </Routes>
            </div>
        </main>
    );
};

export default MainContent;