import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { ReadWarehouseDto } from '../../models/WarehouseModel';
import { useTranslation } from 'react-i18next';

interface ViewWarehouseModalProps {
    open: boolean;
    onClose: () => void;
    warehouse: ReadWarehouseDto | null;
}

const ViewWarehouseModal: React.FC<ViewWarehouseModalProps> = ({ open, onClose, warehouse }) => {
    const { t } = useTranslation();

    if (!warehouse) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    {t('warehouseDetails')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <strong>{t('warehouseCode')}:</strong> {warehouse.whCode}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('warehouseName')}:</strong> {warehouse.whName}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('warehouseDescription')}:</strong> {warehouse.whDescription || t('notProvided')}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('createdDate')}:</strong> {new Date(warehouse.cruDate).toLocaleString()}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} variant="contained">
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewWarehouseModal;