import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import { Customer, UpdateCustomerDto } from '../../models/Customer';
import { useTranslation } from 'react-i18next';

interface EditCustomerModalProps {
    open: boolean;
    onClose: () => void;
    customer: Customer | null;
    onUpdateCustomer: (customer: UpdateCustomerDto) => void;
}

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({ open, onClose, customer, onUpdateCustomer }) => {
    const [customerCode, setCustomerCode] = useState('');
    const [customerName, setCustomerName] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (customer) {
            setCustomerCode(customer.customerCode);
            setCustomerName(customer.customerName);
        }
    }, [customer]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (customer) {
            const updatedCustomer: UpdateCustomerDto = {
                id: customer.id,
                customerCode,
                customerName
            };
            onUpdateCustomer(updatedCustomer);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('editCustomer')}</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label={t('customerCode')}
                        value={customerCode}
                        onChange={(e) => setCustomerCode(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('customerName')}
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t('update')}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default EditCustomerModal;