import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './routing/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardPage from './pages/DashBoard/DashboardPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ScreenSizeService } from './services/ScreenSizeService';
import './styles/global.module.scss';
import { AppService } from './services/AppService';
import AboutPage from './pages/About/AboutPage';
import PrivacyPolicyPage from './pages/Privacy/PrivacyAndPolicy';
import SupportPage from './pages/Support/SupportPage';

const theme = createTheme({
  typography: {
    fontSize: 20,
  },
  spacing: 1,
});

const screenSizeService = ScreenSizeService.getInstance();

function App() {

  AppService.getInstance();

  useEffect(() => {
    const subscription = screenSizeService.screenSize$.subscribe({
      error: (error) => {
        console.error('Error while listening the screen size:', error);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router >
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<PrivateRoute component={DashboardPage} />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/support" element={<SupportPage />} />
        </Routes>
        <ToastContainer />
      </Router>
    </ThemeProvider>

  );
}

export default App;
