import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { CreateStockDto, Stock, UpdateStockDto } from "../models/Stock";
import { ApiResponse } from "../models/ApiResponse";
import { PaginationConst, PaginationModel, getDefaultPagination } from "../const/PaginationConst";
import { PaginationRequestModel } from "../models/PaginationRequestModel";
import UrlLinks from "../const/UrlLinks";
import { OptionsMetadata } from "../models/OptionsMetadata";
import { AppService } from "./AppService";

export class StockService {
  private appService = AppService.getInstance();
  private static instance: StockService;
  private stocksSubject = new BehaviorSubject<ApiResponse<Stock[]>>({
    data: [],
    isSuccess: false,
    message: '',
    status: 0,
    pagination: getDefaultPagination(),
  });

  private paginationSubject = new BehaviorSubject<PaginationModel>(getDefaultPagination());
  private optionsMetadataSubject = new BehaviorSubject<OptionsMetadata | null>(null);

  private constructor() {}

  static getInstance(): StockService {
    if (!StockService.instance) {
      StockService.instance = new StockService();
    }
    return StockService.instance;
  }

  public async loadStocksWithPagination(request: PaginationRequestModel): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<Stock[]>>(UrlLinks.getAllStocksUrl, request);
      if (response.data.isSuccess) {
        this.stocksSubject.next(response.data);
        this.updatePagination(response.data.pagination as PaginationModel);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching stocks with pagination:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);
    }
  }

  private updatePagination(pagination: PaginationModel) {
    this.paginationSubject.next({
      currentPage: pagination.currentPage,
      pageSize: pagination.pageSize,
      totalPages: pagination.totalPages,
      totalRecords: pagination.totalRecords,
    });
  }

  public setPageSize(pageSize: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, pageSize });
    this.loadStocksWithPagination({ 
      pageNumber: PaginationConst.DEFAULT_PAGE_NUMBER, 
      pageSize, 
      filters: {} 
    });
  }

  public setPageNumber(pageNumber: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, currentPage: pageNumber });
    this.loadStocksWithPagination({ 
      pageNumber, 
      pageSize: currentPagination.pageSize, 
      filters: {} 
    });
  }

  public async loadOptionsMetadata(): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.options<ApiResponse<OptionsMetadata>>(UrlLinks.getStockOptionsUrl);
      if (response.data.isSuccess) {
        this.optionsMetadataSubject.next(response.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching options metadata:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async createStock(stock: CreateStockDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<void>>(UrlLinks.createStockUrl, stock);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error creating stock:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async updateStock(stock: UpdateStockDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.put<ApiResponse<void>>(UrlLinks.updateStockUrl, stock);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating stock:', error);
      const errorMessage = axios.isAxiosError(error) && error.response ? error.response.data.message : String(error);
      this.appService.setError(errorMessage);
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async deleteStock(id: string): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.delete<ApiResponse<void>>(`${UrlLinks.deleteStockUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error deleting stock:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async getStockById(id: string): Promise<ApiResponse<Stock>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.get<ApiResponse<Stock>>(`${UrlLinks.getStockByIdUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching stock by id:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  get stocks$() {
    return this.stocksSubject.asObservable();
  }

  get pagination$() {
    return this.paginationSubject.asObservable();
  }

  get optionsMetadata$() {
    return this.optionsMetadataSubject.asObservable();
  }
}