import React, { useEffect, useState } from 'react';
import { useObservable } from '../../utils/useObservable';
import { WarehouseService } from '../../services/WarehouseService';
import GenericTable from '../../components/Data/GenericTable';
import FilterComponent from '../../components/Data/FilterComponent';
import { FiltersModel } from '../../models/Filters';
import styles from '../../styles/WarehousePage.module.scss';
import { getDefaultPagination } from '../../const/PaginationConst';
import PaginationComponent from '../../components/Data/PaginationComponent';
import StandardButton from '../../components/Buttons/StandardButton';
import { Filter, Add } from '@mui/icons-material';
import { WarehouseModel, CreateWarehouseDto, UpdateWarehouseDto } from '../../models/WarehouseModel';
import { ApiResponse } from '../../models/ApiResponse';
import CreateWarehouseModal from './CreateWarehouseModal';
import EditWarehouseModal from './EditWarehouseModal';
import DeleteWarehouseModal from './DeleteWarehouseModal';
import ViewWarehouseModal from './ViewWarehouseModal';
import { useTranslation } from 'react-i18next';

const warehouseService = WarehouseService.getInstance();

const WarehousePage: React.FC = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<FiltersModel>({});
    const pagination = useObservable(warehouseService.pagination$, getDefaultPagination());
    const warehousesResponse = useObservable(warehouseService.warehouses$, undefined) as ApiResponse<WarehouseModel[]>;
    const optionsMetadata = useObservable(warehouseService.optionsMetadata$, undefined);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseModel | null>(null);

    useEffect(() => {
        loadWarehouses(pagination.currentPage, pagination.pageSize, filters);
        warehouseService.loadOptionsMetadata();
    }, []);

    const loadWarehouses = (pageNumber: number, pageSize: number, filters: FiltersModel) => {
        warehouseService.loadWarehousesWithPagination({ pageNumber, pageSize, filters });
    };

    const handleFilter = (newFilters: FiltersModel) => {
        setFilters(newFilters);
        loadWarehouses(pagination.currentPage, pagination.pageSize, newFilters);
    };

    const handlePageChange = (pageNumber: number) => {
        warehouseService.setPageNumber(pageNumber);
        loadWarehouses(pageNumber, pagination.pageSize, filters);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        warehouseService.setPageSize(newPageSize);
        loadWarehouses(pagination.currentPage, newPageSize, filters);
    };

    const handleView = (warehouse: WarehouseModel) => {
        warehouseService.getWarehouseById(warehouse.id)
            .then(response => {
                if (response.isSuccess) {
                    setSelectedWarehouse(response.data as WarehouseModel);
                    setViewModalOpen(true);
                } else {
                    console.error(t('errorRetrievingWarehouse'), response.message);
                }
            })
            .catch(error => {
                console.error(t('errorRetrievingWarehouse'), error);
            });
    };

    const handleEdit = (warehouse: WarehouseModel) => {
        setSelectedWarehouse(warehouse);
        setEditModalOpen(true);
    };

    const handleDelete = (warehouse: WarehouseModel) => {
        setSelectedWarehouse(warehouse);
        setDeleteModalOpen(true);
    };

    const handleCreate = () => {
        setCreateModalOpen(true);
    };

    const columns = [
        { field: 'whCode', title: t('warehouseCode') },
        { field: 'whName', title: t('warehouseName') },
        { field: 'whDescription', title: t('warehouseDescription') },
        { field: 'isActive', title: t('isActive') },
        { field: 'cruDate', title: t('createdDate') },
    ];

    return (
        <div className={styles.mainContainer}>
            <div className={styles.buttonContainer}>
                <StandardButton
                    variant="primary"
                    onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
                    icon={<Filter />}
                >
                    {t('filter')}
                </StandardButton>
                <StandardButton
                    variant="primary"
                    onClick={handleCreate}
                    icon={<Add />}
                >
                    {t('newWarehouse')}
                </StandardButton>
            </div>

            <div className={`${styles.filterPanel} ${isFilterPanelOpen ? styles.open : styles.close}`}>
                {optionsMetadata && (
                    <FilterComponent<WarehouseModel>
                        columns={columns}
                        onFilter={handleFilter}
                        optionsMetadata={optionsMetadata}
                        currentFilters={filters}
                    />
                )}
            </div>

            <div className={`${styles.content} ${isFilterPanelOpen ? styles.filtered : ''}`}>
                <div className={styles.tableContainer}>
                    <GenericTable
                        data={warehousesResponse?.data || []}
                        columns={columns}
                        onView={handleView}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </div>

                <div className={styles.paginationContainer}>
                    <PaginationComponent
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>

            <CreateWarehouseModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onCreateWarehouse={(newWarehouse: CreateWarehouseDto) => {
                    warehouseService.createWarehouse(newWarehouse)
                        .then(response => {
                            if (response.isSuccess) {
                                loadWarehouses(pagination.currentPage, pagination.pageSize, filters);
                                warehouseService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorCreatingWarehouse'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorCreatingWarehouse'), error);
                        });
                    setCreateModalOpen(false);
                }}
            />

            <ViewWarehouseModal
                open={viewModalOpen}
                onClose={() => setViewModalOpen(false)}
                warehouse={selectedWarehouse}
            />

            <EditWarehouseModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                warehouse={selectedWarehouse}
                onUpdateWarehouse={(updatedWarehouse: UpdateWarehouseDto) => {
                    warehouseService.updateWarehouse(updatedWarehouse)
                        .then(response => {
                            if (response.isSuccess) {
                                loadWarehouses(pagination.currentPage, pagination.pageSize, filters);
                                warehouseService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorUpdatingWarehouse'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorUpdatingWarehouse'), error);
                        });
                    setEditModalOpen(false);
                }}
            />

            <DeleteWarehouseModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                warehouse={selectedWarehouse}
                onDeleteWarehouse={(warehouseId: string) => {
                    warehouseService.deleteWarehouse(warehouseId)
                        .then(response => {
                            if (response.isSuccess) {
                                loadWarehouses(pagination.currentPage, pagination.pageSize, filters);
                                warehouseService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorDeletingWarehouse'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorDeletingWarehouse'), error);
                        });
                    setDeleteModalOpen(false);
                }}
            />
        </div>
    );
};

export default WarehousePage;