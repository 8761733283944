import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { Stock } from '../../models/Stock';
import { useTranslation } from 'react-i18next';

interface ViewStockModalProps {
    open: boolean;
    onClose: () => void;
    stock: Stock | null;
}

const ViewStockModal: React.FC<ViewStockModalProps> = ({ open, onClose, stock }) => {
    const { t } = useTranslation();

    if (!stock) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    {t('stockDetails')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <strong>{t('stockCode')}:</strong> {stock.stoCode}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('stockName')}:</strong> {stock.stoName}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('stockDescription')}:</strong> {stock.stoDescription}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>{t('canBeBelowZero')}:</strong> {stock.stoCanBeBelowZero ? t('yes') : t('no')}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} variant="contained">
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewStockModal;