import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { CreateCustomerDto, Customer, UpdateCustomerDto } from "../models/Customer";
import { ApiResponse } from "../models/ApiResponse";
import { PaginationConst, PaginationModel, getDefaultPagination } from "../const/PaginationConst";
import { PaginationRequestModel } from "../models/PaginationRequestModel";
import UrlLinks from "../const/UrlLinks";
import { OptionsMetadata } from "../models/OptionsMetadata";
import { AppService } from "./AppService";

export class CustomerService {
  private appService = AppService.getInstance();
  private static instance: CustomerService;
  private customersSubject = new BehaviorSubject<ApiResponse<Customer[]>>({
    data: [],
    isSuccess: false,
    message: '',
    status: 0,
    pagination: getDefaultPagination(),
  });

  private paginationSubject = new BehaviorSubject<PaginationModel>(getDefaultPagination());
  private optionsMetadataSubject = new BehaviorSubject<OptionsMetadata | null>(null);

  private constructor() {}

  static getInstance(): CustomerService {
    if (!CustomerService.instance) {
      CustomerService.instance = new CustomerService();
    }
    return CustomerService.instance;
  }

  public async loadCustomersWithPagination(request: PaginationRequestModel): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<Customer[]>>(UrlLinks.getAllCustomersUrl, request);
      if (response.data.isSuccess) {
        this.customersSubject.next(response.data);
        this.updatePagination(response.data.pagination as PaginationModel);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching customers with pagination:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false); 
    }
  }

  private updatePagination(pagination: PaginationModel) {
    this.paginationSubject.next(pagination);
  }

  public setPageSize(pageSize: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, pageSize });
    this.loadCustomersWithPagination({ 
      pageNumber: PaginationConst.DEFAULT_PAGE_NUMBER, 
      pageSize, 
      filters: {} 
    });
  }

  public setPageNumber(pageNumber: number) {
    const currentPagination = this.paginationSubject.value;
    this.paginationSubject.next({ ...currentPagination, currentPage: pageNumber });
    this.loadCustomersWithPagination({ 
      pageNumber, 
      pageSize: currentPagination.pageSize, 
      filters: {} 
    });
  }

  public async loadOptionsMetadata(): Promise<void> {
    try {
      this.appService.setLoading(true);
      const response = await axios.options<ApiResponse<OptionsMetadata>>(UrlLinks.getCustomerOptionsUrl);
      if (response.data.isSuccess) {
        this.optionsMetadataSubject.next(response.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching options metadata:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
    } finally {
      this.appService.setLoading(false);  
    }
  }

  public async createCustomer(customer: CreateCustomerDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.post<ApiResponse<void>>(UrlLinks.createCustomerUrl, customer);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error creating customer:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async updateCustomer(customer: UpdateCustomerDto): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.put<ApiResponse<void>>(UrlLinks.updateCustomerUrl, customer);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating customer:', error);
      const errorMessage = axios.isAxiosError(error) && error.response ? error.response.data.message : String(error);
      this.appService.setError(errorMessage);
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async deleteCustomer(id: string): Promise<ApiResponse<void>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.delete<ApiResponse<void>>(`${UrlLinks.deleteCustomerUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error deleting customer:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  public async getCustomerById(id: string): Promise<ApiResponse<Customer>> {
    try {
      this.appService.setLoading(true);
      const response = await axios.get<ApiResponse<Customer>>(`${UrlLinks.getCustomerByIdUrl}/${id}`);
      if (!response.data.isSuccess) {
        throw new Error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching customer by id:', error);
      this.appService.setError(error instanceof Error ? error.message : String(error));
      throw error;
    } finally {
      this.appService.setLoading(false);
    }
  }

  get customers$() {
    return this.customersSubject.asObservable();
  }

  get pagination$() {
    return this.paginationSubject.asObservable();
  }

  get optionsMetadata$() {
    return this.optionsMetadataSubject.asObservable();
  }
}