import React from 'react';
import { Assessment, FireTruck, Home, Inventory, Money, Person, Repartition, Store } from '@mui/icons-material';
import SumaryReport from '../../pages/SumaryReport';
import StocksPage from '../../pages/Stock/StocksPage';
import CustomersPage from '../../pages/Customer/CustomersPage';
import OrdersPage from '../../pages/Order/OrdersPage';
import InventoryReport from '../../pages/Reports/InventoryReport';
import OrderReport from '../../pages/Reports/OrderReport';
import WarehousePage from '../Warehouse/WarehousePage';

export interface MenuItemModel {
    textKey: string;
    url?: string;
    icon: React.ReactNode;
    element?: React.ReactNode;
    subMenuItems?: MenuItemModel[];
}

export const menuItems: MenuItemModel[] = [
    { textKey: 'SummaryReports', url: '/SumaryReport', icon: <Home />, element: <SumaryReport /> },
    { textKey: 'Stocks', url: '/stocks/list', icon: <Inventory />, element: <StocksPage /> },
    { textKey: 'Customers', url: '/customers/list', icon: <Person />, element: <CustomersPage /> },
    { textKey: 'Orders', url: '/orders/list', icon: <Money />, element: <OrdersPage /> },
    { textKey: 'Warehouses', url: '/warehouses/list', icon: <FireTruck />, element: <WarehousePage /> },
    {
        textKey: 'Reports',
        icon: <Assessment />,
        subMenuItems: [
            { textKey: 'InventoryReport', url: '/reports/InventoryReport', icon: <Store />, element: <InventoryReport /> },
            { textKey: 'OrderReport', url: '/reports/OrderReport', icon: <Repartition />, element: <OrderReport /> }
        ]
    }
];
