import React from 'react'

const SumaryReport = () => {
    return (

        <div>
            <h2>SumaryReport</h2>
            <p>SumaryReport</p>
            <p>SumaryReport</p>
            <p>SumaryReport</p>
            <p>SumaryReport</p>
        </div>
    )
}

export default SumaryReport