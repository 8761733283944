import React from 'react';
import { Pagination as MUIPagination, PaginationItem, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { FirstPage, LastPage } from '@mui/icons-material';
import { PaginationConst, PaginationModel } from '../../const/PaginationConst';
import { useTranslation } from 'react-i18next';

interface PaginationComponentProps {
    pagination: PaginationModel
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
    pagination,
    onPageChange,
    onPageSizeChange
}) => {
    const { t } = useTranslation();

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        onPageChange(page);
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        onPageSizeChange(event.target.value as number);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
            <FormControl variant="outlined" size="small">
                <InputLabel id="page-size-select-label">{t('pageSize')}</InputLabel>
                <Select<number>
                    labelId="page-size-select-label"
                    id="page-size-select"
                    value={pagination.pageSize}
                    onChange={handlePageSizeChange}
                    label={t('pageSize')}
                >
                    {PaginationConst.PAGE_SIZE_OPTIONS.map((size) => (
                        <MenuItem key={size} value={size}>{size}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <MUIPagination
                count={pagination.totalPages}
                page={pagination.currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            first: FirstPage,
                            last: LastPage,
                        }}
                        {...item}
                    />
                )}
            />
            <div>
                {(pagination.currentPage - 1) * pagination.pageSize + 1} - {Math.min(pagination.currentPage * pagination.pageSize, pagination.totalRecords)} {t('of')} {pagination.totalRecords} {t('items')}
            </div>
        </div>
    );
};

export default PaginationComponent;