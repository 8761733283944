export const PaginationConst = {
  DEFAULT_PAGE_NUMBER: 1,
  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_TOTAL_PAGES: 0,
  PAGE_SIZE_OPTIONS: [5, 10, 20, 50, 100],
};
 
export interface PaginationModel {
  totalRecords: number; 
  totalPages: number; 
  currentPage: number; 
  pageSize: number; 
  }
  

export const getDefaultPagination = (): PaginationModel => ({
  currentPage: PaginationConst.DEFAULT_PAGE_NUMBER,
  pageSize: PaginationConst.DEFAULT_PAGE_SIZE,
  totalPages: PaginationConst.DEFAULT_TOTAL_PAGES,
  totalRecords: 0,
});