import { BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import axios from 'axios';
import UrlLinks from '../const/UrlLinks';


// const api = axios.create({
//   withCredentials: false,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });

class AuthService {
  private readonly tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(localStorage.getItem('token'));
 
  validateToken(token: string) {
    const data = { token };  
    return from(axios.post(`${UrlLinks.validateTokenUrl}`, data, {
      headers: { 'Content-Type': 'application/json' }
    })).pipe(
      map(response => {
        if (response.data.isSuccess) {
          this.setToken(token);
        } else {
          this.clearToken();
        }
        return response.data;
      })
    );
  }

  signIn(userName: string, password: string) {
    console.log('signIn url:',UrlLinks.loginUrl);
    return from(axios.post(`${UrlLinks.loginUrl}`, { userName, password })).pipe(
      map(response => {
        if (response.data.isSuccess) {
          this.setToken(response.data.data); 
        } else {
          this.clearToken();
        }
        return response.data;
      })
    );
  }
  setToken(token: string) {
    localStorage.setItem('token', token);
    this.tokenSubject.next(token);
  }

  clearToken() {
    localStorage.removeItem('token');
    this.tokenSubject.next(null);
  }

  getToken() {
    return this.tokenSubject.asObservable();
  }
}

export const authService = new AuthService();
