// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set base font size for the entire application */
html {
  font-size: 62.5%; /* This makes 1rem = 10px */
}

/* Set font size for all text elements except headings */
body {
  font-size: 1rem; /* This equals 10px */
  line-height: 1.5; /* Adjust as needed */
  font-family: Arial, sans-serif; /* Use your preferred font */
}

/* Keep heading sizes relative to the base font size */
h1, h2, h3, h4, h5, h6 {
  font-size: revert; /* This keeps the browser's default relative sizing for headings */
}

/* Adjust input and button text sizes */
input, button, select, textarea {
  font-size: 1rem;
}

/* Adjust for specific components if needed */
.global_standardButton__AvkcV {
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.module.scss"],"names":[],"mappings":"AACA,yBAAA;AACA;EACI,SAAA;EACA,UAAA;EACA,sBAAA;AAAJ;;AAGE,kDAAA;AACA;EACE,gBAAA,EAAA,2BAAA;AAAJ;;AAGE,wDAAA;AACA;EACE,eAAA,EAAA,qBAAA;EACA,gBAAA,EAAA,qBAAA;EACA,8BAAA,EAAA,4BAAA;AAAJ;;AAGE,sDAAA;AACA;EACE,iBAAA,EAAA,kEAAA;AAAJ;;AAGE,uCAAA;AACA;EACE,eAAA;AAAJ;;AAGE,6CAAA;AACA;EACE,eAAA;AAAJ","sourcesContent":[" \n/* Reset default styles */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  /* Set base font size for the entire application */\n  html {\n    font-size: 62.5%; /* This makes 1rem = 10px */\n  }\n  \n  /* Set font size for all text elements except headings */\n  body {\n    font-size: 1rem; /* This equals 10px */\n    line-height: 1.5; /* Adjust as needed */\n    font-family: Arial, sans-serif; /* Use your preferred font */\n  }\n  \n  /* Keep heading sizes relative to the base font size */\n  h1, h2, h3, h4, h5, h6 {\n    font-size: revert; /* This keeps the browser's default relative sizing for headings */\n  }\n  \n  /* Adjust input and button text sizes */\n  input, button, select, textarea {\n    font-size: 1rem;\n  }\n  \n  /* Adjust for specific components if needed */\n  .standardButton {\n    font-size: 1rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standardButton": `global_standardButton__AvkcV`
};
export default ___CSS_LOADER_EXPORT___;
