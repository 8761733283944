import React, { useEffect, useState } from 'react';
import { useObservable } from '../../utils/useObservable';
import { OrderService } from '../../services/OrderService';
import GenericTable from '../../components/Data/GenericTable';
import FilterComponent from '../../components/Data/FilterComponent';
import { FiltersModel } from '../../models/Filters';
import styles from '../../styles/OrdersPage.module.scss';
import { getDefaultPagination } from '../../const/PaginationConst';
import PaginationComponent from '../../components/Data/PaginationComponent';
import StandardButton from '../../components/Buttons/StandardButton';
import { Filter, Add } from '@mui/icons-material';
import { OrderModel, CreateOrderDto, UpdateOrderDto, OrderType } from '../../models/Order';
import { ApiResponse } from '../../models/ApiResponse';
import CreateOrderModal from './CreateOrderModal';
import EditOrderModal from './EditOrderModal';
import DeleteOrderModal from './DeleteOrderModal';
import ViewOrderModal from './ViewOrderModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const orderService = OrderService.getInstance();

const OrdersPage: React.FC = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<FiltersModel>({});
    const pagination = useObservable(orderService.pagination$, getDefaultPagination());
    const ordersResponse = useObservable(orderService.orders$, undefined) as ApiResponse<OrderModel[]>;
    const optionsMetadata = useObservable(orderService.optionsMetadata$, undefined);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<OrderModel | null>(null);

    useEffect(() => {
        loadOrders(pagination.currentPage, pagination.pageSize, filters);
        orderService.loadOptionsMetadata();
    }, []);

    const loadOrders = (pageNumber: number, pageSize: number, filters: FiltersModel) => {
        orderService.loadOrdersWithPagination({ pageNumber, pageSize, filters });
    };

    const handleFilter = (newFilters: FiltersModel) => {
        setFilters(newFilters);
        loadOrders(pagination.currentPage, pagination.pageSize, newFilters);
    };

    const handlePageChange = (pageNumber: number) => {
        orderService.setPageNumber(pageNumber);
        loadOrders(pageNumber, pagination.pageSize, filters);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        orderService.setPageSize(newPageSize);
        loadOrders(pagination.currentPage, newPageSize, filters);
    };

    const handleView = (order: OrderModel) => {
        setSelectedOrder(order);
        setViewModalOpen(true);
    };

    const handleEdit = (order: OrderModel) => {
        setSelectedOrder(order);
        setEditModalOpen(true);
    };

    const handleDelete = (order: OrderModel) => {
        setSelectedOrder(order);
        setDeleteModalOpen(true);
    };

    const handleCreate = () => {
        setCreateModalOpen(true);
    };

    const columns = [
        { field: 'ordDocSerial', title: t('documentSerial') },
        { field: 'ordSequence', title: t('sequence') },
        { field: 'ordDate', title: t('orderDate'), render: (value: string) => new Date(value).toLocaleDateString() },
        { field: 'orderType', title: t('orderType'), render: (value: OrderType) => t(`orderType${value}`) },
        { field: 'ordCustomerName', title: t('customer') },
        { field: 'ordWarehouseName', title: t('warehouse') },
    ];

    return (
        <div className={styles.mainContainer}>
            <div className={styles.buttonContainer}>
                <StandardButton
                    variant="primary"
                    onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
                    icon={<Filter />}
                >
                    {t('filter')}
                </StandardButton>
                <StandardButton
                    variant="primary"
                    onClick={handleCreate}
                    icon={<Add />}
                >
                    {t('newOrder')}
                </StandardButton>
            </div>

            <div className={`${styles.filterPanel} ${isFilterPanelOpen ? styles.open : styles.close}`}>
                {optionsMetadata && (
                    <FilterComponent<OrderModel>
                        columns={columns}
                        onFilter={handleFilter}
                        optionsMetadata={optionsMetadata}
                        currentFilters={filters}
                    />
                )}
            </div>

            <div className={`${styles.content} ${isFilterPanelOpen ? styles.filtered : ''}`}>
                <div className={styles.tableContainer}>
                    <GenericTable
                        data={ordersResponse?.data || []}
                        columns={columns}
                        onView={handleView}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </div>

                <div className={styles.paginationContainer}>
                    <PaginationComponent
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>

            <CreateOrderModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onCreateOrder={(newOrder: CreateOrderDto) => {
                    orderService.createOrder(newOrder)
                        .then(response => {
                            if (response.isSuccess) {
                                toast.success(t('orderCreatedSuccessfully'));
                                loadOrders(pagination.currentPage, pagination.pageSize, filters);
                                orderService.loadOptionsMetadata();
                            } else {
                                toast.error(t('errorCreatingOrder'));
                            }
                        })
                        .catch(() => toast.error(t('errorCreatingOrder')));
                    setCreateModalOpen(false);
                }}
            />

            <ViewOrderModal
                open={viewModalOpen}
                onClose={() => setViewModalOpen(false)}
                order={selectedOrder}
            />

            <EditOrderModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                order={selectedOrder}
                onUpdateOrder={(updatedOrder: UpdateOrderDto) => {
                    orderService.updateOrder(updatedOrder)
                        .then(response => {
                            if (response.isSuccess) {
                                toast.success(t('orderUpdatedSuccessfully'));
                                loadOrders(pagination.currentPage, pagination.pageSize, filters);
                                orderService.loadOptionsMetadata();
                            } else {
                                toast.error(t('errorUpdatingOrder'));
                            }
                        })
                        .catch(() => toast.error(t('errorUpdatingOrder')));
                    setEditModalOpen(false);
                }}
            />

            <DeleteOrderModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                order={selectedOrder}
                onDeleteOrder={(orderId: string) => {
                    orderService.deleteOrder(orderId)
                        .then(response => {
                            if (response.isSuccess) {
                                toast.success(t('orderDeletedSuccessfully'));
                                loadOrders(pagination.currentPage, pagination.pageSize, filters);
                                orderService.loadOptionsMetadata();
                            } else {
                                toast.error(t('errorDeletingOrder'));
                            }
                        })
                        .catch(() => toast.error(t('errorDeletingOrder')));
                    setDeleteModalOpen(false);
                }}
            />
        </div>
    );
};

export default OrdersPage;