import React from 'react';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface GenericTableProps {
    data: any[];
    columns: { field: string; title: string }[];
    onView: (row: any) => void;
    onEdit: (row: any) => void;
    onDelete: (row: any) => void;
}

const GenericTable: React.FC<GenericTableProps> = ({ data, columns, onView, onEdit, onDelete }) => {
    const { t } = useTranslation();

    const mrtColumns: MRT_ColumnDef<any>[] = columns.map((column) => ({
        accessorKey: column.field,
        header: t(column.title),
        Cell: ({ cell }) => <>{cell.getValue()}</>,
    }));

    mrtColumns.push({
        id: 'actions',
        header: t('actions'),
        Cell: ({ row }) => (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Tooltip title={t('view')}>
                    <IconButton onClick={() => onView(row.original)}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('edit')}>
                    <IconButton onClick={() => onEdit(row.original)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                    <IconButton onClick={() => onDelete(row.original)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        ),
    });

    return (
        <>
            {data && (
                <div>
                    <MaterialReactTable
                        columns={mrtColumns}
                        data={data}
                        enableSorting
                        enableColumnFilters={false}
                        enableColumnDragging={false}
                        enableColumnOrdering
                        enablePagination={false}
                        enableRowSelection={true}
                        enableRowActions={false}
                        enableColumnActions={false}
                        enableEditing={false}
                        enableFilters={false}
                        enableExpandAll={false}
                        enableFullScreenToggle={true}
                        enableToolbarInternalActions={false}
                        enableDensityToggle={false}
                        initialState={{ density: 'compact' }}
                        enableTopToolbar={false}
                        localization={{
                            actions: t('actions'),
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default GenericTable;