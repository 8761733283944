import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import { Customer } from '../../models/Customer';
import { useTranslation } from 'react-i18next';
import { CustomerService } from '../../services/CustomerService';

interface CustomerSelectionModalProps {
    open: boolean;
    onClose: () => void;
    onSelectCustomer: (customer: Customer) => void;
}

const CustomerSelectionModal: React.FC<CustomerSelectionModalProps> = ({ open, onClose, onSelectCustomer }) => {
    const { t } = useTranslation();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const customerService = CustomerService.getInstance();

    useEffect(() => {
        if (open) {
            loadCustomers();
        }
    }, [open]);

    const loadCustomers = () => {
        customerService.loadCustomersWithPagination({ pageNumber: 1, pageSize: 1000, filters: {} })
            .then(() => {
                const customerSubscription = customerService.customers$.subscribe(response => {
                    if (response.isSuccess) {
                        setCustomers(response.data);
                        setFilteredCustomers(response.data);
                    }
                });
                return () => customerSubscription.unsubscribe();
            });
    };

    useEffect(() => {
        const filtered = customers.filter(customer =>
            customer.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            customer.customerCode.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCustomers(filtered);
    }, [searchTerm, customers]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('selectCustomer')}</h2>
                <TextField
                    fullWidth
                    label={t('search')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    margin="normal"
                />
                <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                    {filteredCustomers.map((customer) => (
                        <ListItem
                            key={customer.id}
                            button
                            onClick={() => {
                                onSelectCustomer(customer);
                                onClose();
                            }}
                        >
                            <ListItemText
                                primary={customer.customerName}
                                secondary={customer.customerCode}
                            />
                        </ListItem>
                    ))}
                </List>
                <Button onClick={onClose} variant="contained" color="secondary" sx={{ mt: 2 }}>
                    {t('cancel')}
                </Button>
            </Box>
        </Modal>
    );
};

export default CustomerSelectionModal;