import React, { useState } from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Collapse, TextField, InputAdornment, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Resizable } from 'react-resizable';
import styles from '../../styles/Dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { MenuItemModel } from './MenuItems';
import logo from '../../images/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

interface SidebarProps {
    drawerOpen: boolean;
    drawerWidth: number;
    handleResize: (event: React.SyntheticEvent, { size }: { size: { width: number, height: number } }) => void;
    menuItems: MenuItemModel[];
    location: ReturnType<typeof useLocation>;
}

const Sidebar: React.FC<SidebarProps> = ({ drawerOpen, drawerWidth, handleResize, menuItems, location }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const [openMenuItems, setOpenMenuItems] = useState<string[]>([]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const isActive = (menuItem: MenuItemModel): boolean => {
        return menuItem.url ? location.pathname === menuItem.url : location.pathname.startsWith(`/${menuItem.textKey}`);
    };

    const handleMenuItemClick = (menuItem: MenuItemModel) => {
        if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
            if (openMenuItems.includes(menuItem.textKey)) {
                setOpenMenuItems(openMenuItems.filter((item) => item !== menuItem.textKey));
            } else {
                setOpenMenuItems([...openMenuItems, menuItem.textKey]);
            }
        } else if (menuItem.url) {
            navigate(menuItem.url);
        }
    };

    function filterMenuItems(menuItems: MenuItemModel[], searchText: string): MenuItemModel[] {
        searchText = searchText.toLowerCase();
        return menuItems.reduce<MenuItemModel[]>((filtered, item) => {
            const subMenuItems = item.subMenuItems ? filterMenuItems(item.subMenuItems, searchText) : [];

            if (item.textKey.toLowerCase().includes(searchText) || subMenuItems.length > 0) {
                filtered.push({
                    ...item,
                    subMenuItems: subMenuItems.length > 0 ? subMenuItems : undefined
                });
            }

            return filtered;
        }, []);
    }

    const filteredMenuItems = filterMenuItems(menuItems, searchText);

    const renderSubMenuItems = (subItems: MenuItemModel[]) => (
        <List component="div" disablePadding>
            {subItems.map((subItem) => (
                <React.Fragment key={subItem.textKey}>
                    <ListItemButton
                        className={isActive(subItem) ? `${styles.menuItem} ${styles.activeSubMenu}` : styles.menuItem}
                        onClick={() => handleMenuItemClick(subItem)}
                        style={{ paddingLeft: subItem.subMenuItems ? '2rem' : '3rem' }}
                    >
                        <ListItemIcon className={isActive(subItem) ? styles.activeSubMenu : ""}>
                            {subItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={t(subItem.textKey)} />
                        {subItem.subMenuItems && (
                            openMenuItems.includes(subItem.textKey) ? <ExpandLess /> : <ExpandMore />
                        )}
                    </ListItemButton>
                    {subItem.subMenuItems && (
                        <Collapse in={openMenuItems.includes(subItem.textKey)} timeout="auto" unmountOnExit>
                            {renderSubMenuItems(subItem.subMenuItems)}
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
        </List>
    );

    return (
        <Drawer
            className={styles.drawer}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    marginTop: '64px',
                },
            }}
        >
            <div className={styles.drawerHeader}>
                <img src={logo} alt="Logo" className={styles.logo} />
            </div>
            <Divider />
            <div className={styles.searchContainerx}>
                <TextField
                    label="Ara"
                    variant="outlined"
                    margin="normal"
                    value={searchText}
                    onChange={handleSearchChange}
                    style={{ width: drawerWidth * 0.95 }}
                    className={styles.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <List>
                {filteredMenuItems.map((item) => (
                    <React.Fragment key={item.textKey}>
                        <ListItemButton
                            className={isActive(item) ? `${styles.menuItem} ${styles.activeMenuItem}` : styles.menuItem}
                            onClick={() => handleMenuItemClick(item)}
                        >
                            <ListItemIcon className={isActive(item) ? styles.activeMenuItem : ""}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={t(item.textKey)} />
                            {item.subMenuItems && (
                                openMenuItems.includes(item.textKey) ? <ExpandLess /> : <ExpandMore />
                            )}
                        </ListItemButton>
                        {item.subMenuItems && (
                            <Collapse in={openMenuItems.includes(item.textKey)} timeout="auto" unmountOnExit>
                                {renderSubMenuItems(item.subMenuItems)}
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </List>
            <Resizable
                width={drawerWidth}
                height={0}
                onResize={handleResize}
                resizeHandles={['e']}
                handle={<div className={styles.resizeHandle} />}
            >
                <div />
            </Resizable>
        </Drawer>
    );
};

export default Sidebar;