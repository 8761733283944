import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../styles/LoginPage.module.scss';
import logo from '../images/LoginLogo.png';
import { authService } from '../services/AuthService';
import { toast } from 'react-toastify';
import LanguageSelector from '../components/LanguageSelector';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        authService.signIn(username, password).subscribe({
            next: response => {
                if (response.isSuccess) {
                    toast.success(t('success'));
                    navigate('/SumaryReport');
                } else {
                    toast.error(t('failed', { reason: response.message }));
                }
            },
            error: error => {
                toast.error(t('error', { error }));
                setIsLoading(false);
            },
            complete: () => {
                setIsLoading(false);
            }
        });
    };

    return (
        <div className={styles.loginContainer}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <form className={styles.loginForm} onSubmit={handleLogin}>
                <h2>{t("title")}</h2>
                <div className={styles.formGroup} >
                    <input type="text" id="username" placeholder={t('username')} value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className={styles.formGroup}>
                    <input type="password" id="password" placeholder={t('password')} value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className={styles.submitButton} disabled={isLoading}>
                    {isLoading ? t('loading') : t('loginButton')}
                </button>
                <LanguageSelector />
            </form>
        </div>
    );
};

export default LoginPage;
