import React, { ReactNode } from 'react';
import styles from '../../styles/Button/StandartButton.module.scss';

interface StandardButtonProps {
    variant?: 'primary' | 'secondary' | 'tertiary';
    onClick?: () => void;
    children?: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
    iconPosition?: 'left' | 'right';
}

const StandardButton: React.FC<StandardButtonProps> = ({
    variant = 'primary',
    onClick,
    children,
    disabled = false,
    icon,
    iconPosition = 'left'
}) => {
    return (
        <button
            className={`${styles.standardButton} ${styles[variant]} ${icon ? styles.withIcon : ''} ${styles[`icon${iconPosition.charAt(0).toUpperCase() + iconPosition.slice(1)}`]}`}
            onClick={onClick}
            disabled={disabled}
        >
            {icon && iconPosition === 'left' && <span className={styles.icon}>{icon}</span>}
            <span className={styles.text}>{children}</span>
            {icon && iconPosition === 'right' && <span className={styles.icon}>{icon}</span>}
        </button>
    );
};

export default StandardButton;