import React, { useEffect, useState } from 'react';
import { useObservable } from '../../utils/useObservable';
import { StockService } from '../../services/StockService';
import GenericTable from '../../components/Data/GenericTable';
import FilterComponent from '../../components/Data/FilterComponent';
import { FiltersModel } from '../../models/Filters';
import styles from '../../styles/StocksPage.module.scss';
import { getDefaultPagination } from '../../const/PaginationConst';
import PaginationComponent from '../../components/Data/PaginationComponent';
import StandardButton from '../../components/Buttons/StandardButton';
import { Filter, Add } from '@mui/icons-material';
import { Stock, CreateStockDto, UpdateStockDto } from '../../models/Stock';
import { ApiResponse } from '../../models/ApiResponse';
import CreateStockModal from './CreateStockModal';
import EditStockModal from './EditStockModal';
import DeleteStockModal from './DeleteStockModal';
import ViewStockModal from './ViewStockModal';
import { useTranslation } from 'react-i18next';

const stockService = StockService.getInstance();

const StockPage: React.FC = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<FiltersModel>({});
    const pagination = useObservable(stockService.pagination$, getDefaultPagination());
    const stocksResponse = useObservable(stockService.stocks$, undefined) as ApiResponse<Stock[]>;
    const optionsMetadata = useObservable(stockService.optionsMetadata$, undefined);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedStock, setSelectedStock] = useState<Stock | null>(null);

    useEffect(() => {
        loadStocks(pagination.currentPage, pagination.pageSize, filters);
        stockService.loadOptionsMetadata();
    }, []);

    const loadStocks = (pageNumber: number, pageSize: number, filters: FiltersModel) => {
        stockService.loadStocksWithPagination({ pageNumber, pageSize, filters });
    };

    const handleFilter = (newFilters: FiltersModel) => {
        setFilters(newFilters);
        loadStocks(pagination.currentPage, pagination.pageSize, newFilters);
    };

    const handlePageChange = (pageNumber: number) => {
        stockService.setPageNumber(pageNumber);
        loadStocks(pageNumber, pagination.pageSize, filters);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        stockService.setPageSize(newPageSize);
        loadStocks(pagination.currentPage, newPageSize, filters);
    };

    const handleView = (stock: Stock) => {
        stockService.getStockById(stock.id)
            .then(response => {
                if (response.isSuccess) {
                    setSelectedStock(stock);
                    setViewModalOpen(true);
                } else {
                    console.error(t('errorRetrievingStock'), response.message);
                }
            })
            .catch(error => {
                console.error(t('errorRetrievingStock'), error);
            });
    };

    const handleEdit = (stock: Stock) => {
        setSelectedStock(stock);
        setEditModalOpen(true);
    };

    const handleDelete = (stock: Stock) => {
        setSelectedStock(stock);
        setDeleteModalOpen(true);
    };

    const handleCreate = () => {
        setCreateModalOpen(true);
    };

    const columns = [
        { field: 'stoCode', title: t('stockCode') },
        { field: 'stoName', title: t('stockName') },
        { field: 'stoDescription', title: t('stockDescription') },
        { field: 'stoCanBeBelowZero', title: t('canBeBelowZero') },
    ];

    return (
        <div className={styles.mainContainer}>
            <div className={styles.buttonContainer}>
                <StandardButton
                    variant="primary"
                    onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
                    icon={<Filter />}
                >
                    {t('filter')}
                </StandardButton>
                <StandardButton
                    variant="primary"
                    onClick={handleCreate}
                    icon={<Add />}
                >
                    {t('newStock')}
                </StandardButton>
            </div>

            <div className={`${styles.filterPanel} ${isFilterPanelOpen ? styles.open : styles.close}`}>
                {optionsMetadata && (
                    <FilterComponent<Stock>
                        columns={columns}
                        onFilter={handleFilter}
                        optionsMetadata={optionsMetadata}
                        currentFilters={filters}
                    />
                )}
            </div>

            <div className={`${styles.content} ${isFilterPanelOpen ? styles.filtered : ''}`}>
                <div className={styles.tableContainer}>
                    <GenericTable
                        data={stocksResponse?.data || []}
                        columns={columns}
                        onView={handleView}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </div>

                <div className={styles.paginationContainer}>
                    <PaginationComponent
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>

            <CreateStockModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onCreateStock={(newStock: CreateStockDto) => {
                    stockService.createStock(newStock)
                        .then(response => {
                            if (response.isSuccess) {
                                loadStocks(pagination.currentPage, pagination.pageSize, filters);
                                stockService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorCreatingStock'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorCreatingStock'), error);
                        });
                    setCreateModalOpen(false);
                }}
            />

            <ViewStockModal
                open={viewModalOpen}
                onClose={() => setViewModalOpen(false)}
                stock={selectedStock}
            />

            <EditStockModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                stock={selectedStock}
                onUpdateStock={(updatedStock: UpdateStockDto) => {
                    stockService.updateStock(updatedStock)
                        .then(response => {
                            if (response.isSuccess) {
                                loadStocks(pagination.currentPage, pagination.pageSize, filters);
                                stockService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorUpdatingStock'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorUpdatingStock'), error);
                        });
                    setEditModalOpen(false);
                }}
            />

            <DeleteStockModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                stock={selectedStock}
                onDeleteStock={(stockId: string) => {
                    stockService.deleteStock(stockId)
                        .then(response => {
                            if (response.isSuccess) {
                                loadStocks(pagination.currentPage, pagination.pageSize, filters);
                                stockService.loadOptionsMetadata();
                            } else {
                                console.error(t('errorDeletingStock'), response.message);
                            }
                        })
                        .catch(error => {
                            console.error(t('errorDeletingStock'), error);
                        });
                    setDeleteModalOpen(false);
                }}
            />
        </div>
    );
};

export default StockPage;