import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import { CreateCustomerDto } from '../../models/Customer';
import { useTranslation } from 'react-i18next';

interface CreateCustomerModalProps {
    open: boolean;
    onClose: () => void;
    onCreateCustomer: (customer: CreateCustomerDto) => void;
}

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({ open, onClose, onCreateCustomer }) => {
    const [customerCode, setCustomerCode] = useState('');
    const [customerName, setCustomerName] = useState('');
    const { t } = useTranslation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const newCustomer: CreateCustomerDto = {
            customerCode,
            customerName
        };
        onCreateCustomer(newCustomer);
        setCustomerCode('');
        setCustomerName('');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('newCustomer')}</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label={t('customerCode')}
                        value={customerCode}
                        onChange={(e) => setCustomerCode(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label={t('customerName')}
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t('create')}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateCustomerModal;