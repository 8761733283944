import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import slogan from '../../images/slogan.png';
import styles from '../../styles/Dashboard.module.scss';
import { useObservable } from '../../utils/useObservable';
import { AppService } from '../../services/AppService';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
    handleDrawerToggle: () => void;
    logoutHandler: () => void;
    appBarHeight: number;
}

const Header: React.FC<HeaderProps> = ({ handleDrawerToggle, logoutHandler, appBarHeight }) => {
    const appService = AppService.getInstance();
    const serviceState = useObservable(appService.serviceStatus$, undefined);
    const { t } = useTranslation();

    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#3f51b5', userSelect: 'none', height: appBarHeight }}  >
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <img src={slogan} alt="Logo" className={styles.slogan} />
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: '11px' }}>
                    {serviceState?.error && t('error')}  {serviceState?.error}
                    {serviceState?.error &&
                        <IconButton style={{ color: 'white' }} onClick={() => appService.setError('')}> <Delete /></IconButton>
                    }
                </Typography>

                <Button color="inherit" onClick={logoutHandler}>
                    {t('logout')}
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;