import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import { CreateWarehouseDto } from '../../models/WarehouseModel';
import { useTranslation } from 'react-i18next';

interface CreateWarehouseModalProps {
    open: boolean;
    onClose: () => void;
    onCreateWarehouse: (warehouse: CreateWarehouseDto) => void;
}

const CreateWarehouseModal: React.FC<CreateWarehouseModalProps> = ({ open, onClose, onCreateWarehouse }) => {
    const [whCode, setWhCode] = useState('');
    const [whName, setWhName] = useState('');
    const [whDescription, setWhDescription] = useState('');
    const { t } = useTranslation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const newWarehouse: CreateWarehouseDto = {
            whCode,
            whName,
            whDescription
        };
        onCreateWarehouse(newWarehouse);
        setWhCode('');
        setWhName('');
        setWhDescription('');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <h2>{t('newWarehouse')}</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label={t('warehouseCode')}
                        value={whCode}
                        onChange={(e) => setWhCode(e.target.value)}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label={t('warehouseName')}
                        value={whName}
                        onChange={(e) => setWhName(e.target.value)}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label={t('warehouseDescription')}
                        value={whDescription}
                        onChange={(e) => setWhDescription(e.target.value)}
                        margin="normal"
                        multiline
                        rows={3}
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t('create')}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateWarehouseModal;